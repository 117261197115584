import { Injectable } from '@angular/core';
import {
  BranchOfficeCountFieldsFragment,
  CalculationRuleFieldsFragment,
  SystemType,
  ValidateBaseConfigurationGQL,
  ValidateBaseConfigurationQuery,
} from '@graphql/generated/graphql';
import { map, Observable } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ConfValidationService {
  private _validateBaseConfigurationQuery: QueryRef<ValidateBaseConfigurationQuery>;

  constructor(
    private readonly _validateBaseConfigurationGQL: ValidateBaseConfigurationGQL,
    private readonly _apollo: Apollo
  ) {
    this._validateBaseConfigurationQuery =
      this._apollo.watchQuery<ValidateBaseConfigurationQuery>({
        query: this._validateBaseConfigurationGQL.document,
      });
  }

  async refetchBaseConfiguration(): Promise<void> {
    await this._validateBaseConfigurationQuery?.refetch();
  }

  hasValidBaseConfiguration(): Observable<boolean> {
    return this._fetchBaseConfigurationValidationFields().pipe(
      map(({ pointSystem, branchOffices, calculationRules }) => {
        switch (pointSystem?.pointSystemType.type) {
          case SystemType.VisitBased: {
            const atLeastOneBranchOffice =
              this._checkAtLeastOneBranchOffice(branchOffices);
            const atLeastOneCalculationRule =
              this._checkAtLeastOneCalculationRule(calculationRules);

            return atLeastOneBranchOffice && atLeastOneCalculationRule;
          }
          // TODO: hier noch neue Bedingungen hinzufügen, sobald es Value-based gibt
          case SystemType.Percentage:
          case SystemType.Staggered: {
            const atLeastOneCalculationRule = false;
            // this._checkAtLeastOneCalculationRule(calculationRules);
            const everyBranchOfficeHasCashDesk = false;
            // this._checkEveryBranchOfficeHasAtLeastOneCashDesk(branchOffices);

            return atLeastOneCalculationRule && everyBranchOfficeHasCashDesk;
          }
          default:
            return false;
        }
      })
    );
  }

  isValueBasedPointSystem(): Observable<boolean | undefined> {
    return this._fetchBaseConfigurationValidationFields().pipe(
      map(value => value.pointSystem?.pointSystemType.type),
      map(type => {
        if (type) {
          return type !== SystemType.VisitBased;
        }
        return undefined;
      })
    );
  }

  private _checkAtLeastOneBranchOffice(
    branchOffices: BranchOfficeCountFieldsFragment[]
  ): boolean {
    return branchOffices.length > 0;
  }

  private _checkAtLeastOneCalculationRule(
    calculationRules: CalculationRuleFieldsFragment[]
  ): boolean {
    return calculationRules.length > 0;
  }

  private _fetchBaseConfigurationValidationFields(): Observable<ValidateBaseConfigurationQuery> {
    return this._validateBaseConfigurationQuery.valueChanges.pipe(
      map(value => value.data)
    );
  }
}
